/********** Template CSS **********/
:root {
  --primary: #7AB730;
  --secondary: #FFD33C;
  --light: #F3F3F3;
  --dark: #212121;
}

[class^=flaticon-]:before,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  margin-left: 0;
}

.btn {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  transition: .5s;
}

.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin-left: 30px;
  padding: 30px 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark);
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link::before {
      position: absolute;
      content: "";
      width: 0;
      height: 7px;
      top: -4px;
      left: 50%;
      background: var(--primary);
      transition: .5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
      width: 100%;
      left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
      width: 100%;
      height: 1px;
      top: -1px;
      left: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link  {
      margin-left: 0;
      padding: 10px 0;
  }
}

/* .hero-header {
  background: url(../img/hero.jpg) top right no-repeat;
  background-size: cover;
} */

.btn-play {
  position: relative;
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 26px;
  border-radius: 100%;
  border: none;
  outline: none !important;
  padding: 18px 20px 20px 28px;
  background: #FFFFFF;
}

.btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 100%;
  transition: all 200ms;
}

.btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  left: -1px;
  border-left: 16px solid var(--primary);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
      opacity: 0;
  }
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}

.service-item a i {
  position: relative;
  padding-left: 20px;
  transition: .3s;
}

.service-item a:hover i {
  padding-left: 50px;
}

.service-item a i::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 10px;
  margin-top: -1px;
  background: var(--primary);
  transition: .3s;
}

.service-item a:hover i::after {
  width: 50px;
}

.product-item {
  padding: 30px;
}

.product-item .btn-action {
  position: absolute;
  width: 100%;
  bottom: -40px;
  left: 0;
  opacity: 0;
  transition: .5s;
}

.product-item:hover .btn-action {
  bottom: -20px;
  opacity: 1;
}

.product-carousel .owl-nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.product-carousel .owl-nav .owl-prev,
.product-carousel .owl-nav .owl-next{
  position: relative;
  margin: 0 5px;
  width: 55px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  font-size: 22px;
  transition: .5s;
}

.product-carousel .owl-nav .owl-prev:hover,
.product-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

/* .bg-offer {
  background: url(../img/offer.jpg) top right no-repeat;
  background-size: cover;
} */

.price-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: var(--primary);
  border-radius: 8px 8px 50% 50%;
  z-index: -1;
}

.price-carousel .owl-nav {
  margin-top: 35px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next{
  position: relative;
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #FFFFFF;
  font-size: 22px;
  border-radius: 45px;
  transition: .5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.team-carousel .owl-nav {
  position: absolute;
  width: 50px;
  height: 160px;
  top: calc(50% - 80px);
  right: 0;
  z-index: 1;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  font-size: 22px;
  transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.team-item img {
  transition: .5s;
}

.team-item:hover img {
  transform: scale(1.2);
}

.team-item .team-overlay {
  position: absolute;
  top: 45px;
  right: 45px;
  bottom: 45px;
  left: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(122, 183, 48, .8);
  transition: .5s;
  opacity: 0;
}

.team-item:hover .team-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
}

/* .bg-testimonial {
  background: url(../img/testimonial.jpg) top left no-repeat;
  background-size: cover;
} */

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 46px;
  top: calc(50% - 23px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  font-size: 22px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}

@media (min-width: 576px) {
  .blog-item .row {
      height: 300px;
  }
}

.blog-item a i {
  position: relative;
  padding-left: 20px;
  transition: .3s;
}

.blog-item a:hover i {
  padding-left: 50px;
}

.blog-item a i::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 10px;
  margin-top: -1px;
  background: var(--primary);
  transition: .3s;
}

.blog-item a:hover i::after {
  width: 50px;
}


