/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --bright-yellow-crayola: hsl(36, 94%, 57%);
  /* --portland-orange: hsl(15, 84%, 57%); */
  --portland-orange: #066cb2;
  --battleship-gray: hsl(0, 0%, 53%);
  --silver-chalice: hsl(0, 0%, 70%);
  --spanish-gray: hsl(0, 0%, 60%);
  --sonic-silver: hsl(208, 7%, 46%);
  --raisin-black: hsl(228, 6%, 17%);
  --eerie-black: hsl(210, 3%, 13%);
  --bittersweet: hsl(9, 96%, 69%);
  --light-gray: hsl(0, 0%, 80%);
  --platinum: hsl(0, 0%, 91%);
  --amber: hsl(45, 100%, 51%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --onyx: hsl(220, 2%, 24%);

  /**
   * typography
   */

  --ff-bangers: 'Bangers', cursive;
  --ff-carter_one: 'Carter One', cursive;
  --ff-nunito_sans: 'Nunito Sans', sans-serif;

  --fs-1: 6.5rem;
  --fs-2: 3.2rem;
  --fs-3: 2.4rem;
  --fs-4: 2rem;
  --fs-5: 1.8rem;
  --fs-6: 1.5rem;
  --fs-7: 1.4rem;
  --fs-8: 1rem;

  --fw-400: 400;
  --fw-700: 700;

  /**
   * spacing
   */

  --section-padding: 40px;

  /**
   * shadow
   */

  --shadow-1: 0 8px 16px hsla(0, 0%, 0%, 0.15);
  --shadow-2: 0 8px 8px hsla(0, 0%, 0%, 0.2);

  /**
   * radius
   */

  --radius-4: 4px;
  --radius-10: 10px;

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a,
img,
span,
button,
ion-icon { display: block; }

a {
  color: inherit;
  text-decoration: none;
}

img { height: auto; }

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
}

ion-icon { pointer-events: none; }

address { font-style: normal; }

html {
  font-family: var(--ff-nunito_sans);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-size: 1.6rem;
  line-height: 1.5;
}

:focus-visible { outline-offset: 4px; }

::-webkit-scrollbar { width: 10px; }

::-webkit-scrollbar-track { background-color: hsl(0, 0%, 98%); }

::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

.section { padding-block: var(--section-padding); }

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h1,
.h2,
.h3 { line-height: 1.1; }

.h1 {
  color: var(--white);
  font-family: var(--ff-bangers);
  font-size: var(--fs-1);
  font-weight: var(--fw-400);
  letter-spacing: 1px;
}

.h2,
.h3 { color: var(--eerie-black); }

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-3); }

.btn {
  /* background-color: var(--black); */
  background-color: var(--portland-orange);
  color: var(--white);
  max-width: max-content;
  padding: 8px 25px;
  font-weight: var(--fw-700);
  border-radius: 50px;
  transition: var(--transition-1);
  border: 1px solid var(--portland-orange)
}

.section-title {
  text-align: center;
  margin-block-end: 30px;
}

.section-title .span {
  display: inline;
  color: var(--portland-orange);
}

.img-holder-category {
  aspect-ratio: var(--width) / var(--height);
  background: linear-gradient(aqua, deepskyblue, #066cb2);
  overflow: hidden;
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  /* background-color: var(--light-gray); */
  overflow: hidden;
}

.img-cover {
  /* width: 100%; */
  /* height: 100%;
  object-fit: cover; */
  
  width: 100%;
  height: 90%;
  object-fit: contain;
  padding-top: 10%;
  margin: 0 auto;
}

.has-scrollbar {
  display: flex;
  align-items: center;
  gap: 30px;
  overflow-x: auto;
  scroll-snap-type: inline mandatory;
  padding-block-end: 20px;
  margin-block-end: -20px;
}

.scrollbar-item {
  min-width: 100%;
  scroll-snap-align: start;
}

.has-scrollbar::-webkit-scrollbar { height: 10px; }

.has-scrollbar::-webkit-scrollbar-track {
  outline: 2px solid var(--portland-orange);
  border-radius: var(--radius-10);
}

.has-scrollbar::-webkit-scrollbar-thumb {
  border-radius: var(--radius-10);
  background-color: var(--portland-orange);
  border: 2px solid var(--white);
}

.has-scrollbar::-webkit-scrollbar-button { width: calc(25% - 30px); }

.grid-list {
  display: grid;
  gap: 30px;
}

.w-100 { width: 100%; }





/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.action-btn.user { display: none; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  padding-block: 10px;
  z-index: 4;
}

.header.active {
  position: fixed;
  box-shadow: var(--shadow-1);
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.nav-toggle-btn {
  font-size: 30px;
  transition: var(--transition-1);
}

.nav-toggle-btn.active .menu-icon,
.nav-toggle-btn .close-icon { display: none; }

.nav-toggle-btn .menu-icon,
.nav-toggle-btn.active .close-icon { display: block; }

.logo {
  font-family: var(--ff-carter_one);
  font-size: 3rem;
  /* border-radius: 50%; */
}

.header-actions {
  display: flex;
  gap: 15px;
}

.header .action-btn {
  position: relative;
  font-size: 22px;
  transition: var(--transition-1);
}

.header .btn-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: var(--portland-orange);
  color: var(--white);
  font-size: var(--fs-8);
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50px;
}

.nav-toggle-btn:is(:hover, :focus),
.header .action-btn:is(:hover, :focus) { transform: scale(1.1); }

.navbar {
  position: fixed;
  top: 85px;
  left: -320px;
  bottom: 0;
  background-color: var(--white);
  max-width: 320px;
  width: 100%;
  padding: 20px 10px;
  box-shadow: var(--shadow-2);
  transition: 0.25s var(--cubic-out);
  overflow-x: hidden;
  overflow-y: auto;
}

.navbar.active {
  transform: translateX(320px);
  transition-timing-function: var(--cubic-in);
}

.navbar-link {
  color: var(--eerie-black);
  font-size: var(--fs-6);
  padding: 10px 15px;
}

.navbar-action-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: var(--sonic-silver);
  color: var(--white);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  text-align: center;
}





/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero {
  margin-block-start: 85px;
  min-height: 360px;
  background-position: left;
  text-align: center;
}

.hero-title .span { font-size: 0.5em; }

.hero-text {
  color: var(--white);
  margin-block: 15px 25px;
}

.hero .btn { margin-inline: auto; }

.hero .btn:is(:hover, :focus) { background-color: var(--portland-orange); color: var(--white) !important }





/*-----------------------------------*\
  #CATEGORY
\*-----------------------------------*/

.category-card .card-banner { border-radius: var(--radius-10); }

.category-card .img-cover { transition: var(--transition-2); }

.category-card:is(:hover, :focus-within) .img-cover { transform: scale(1.08); }

.category-card .card-title {
  margin-block-start: 15px;
  text-align: center;
  transition: var(--transition-1);
}

.category-card .card-title:is(:hover, :focus) { color: var(--portland-orange); }

.category-card-center { 
  display: flex;
  justify-content: center; 
}





/*-----------------------------------*\
  #OFFERS
\*-----------------------------------*/

.offer-card {
  border-radius: var(--radius-10);
  /* padding: 20px; */
  padding: 20px 10px 20px 20px;
  display: grid;
  align-content: center;
}

.offer-card .card-subtitle {
  font-size: var(--fs-7);
  text-transform: uppercase;
  font-weight: var(--fw-700);
}

.offer-card .card-title {
  color: var(--black);
  margin-block: 5px 24px;
}

.offer-card .btn { background-color: var(--portland-orange); color: var(--white) !important}

.offer-card .btn:is(:hover, :focus) { background-color: var(--black); }





/*-----------------------------------*\
  #PRODUCT
\*-----------------------------------*/

.product .img-cover.hover,
.product-card .card-action-btn { display: none; }

.product-card { text-align: center; }

.product-card .card-banner {
  border: 1px solid var(--platinum);
  border-radius: var(--radius-10);
  margin-block-end: 20px;
}

.product-card :is(.wrapper, .rating-wrapper) { display: flex; }

.product-card .wrapper {
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--silver-chalice);
  font-size: var(--fs-7);
}

.product-card .rating-wrapper {
  color: var(--amber);
  gap: 2px;
}

.product-card .rating-wrapper.gray { color: unset; }

.product-card .h3 {
  --fs-3: 1.8rem;
  margin-block: 8px 10px;
}

.product-card .card-title { transition: var(--transition-1); }

.product-card .card-title:is(:hover, :focus) { color: var(--portland-orange); }

.product-card .card-price {
  color: var(--portland-orange);
  font-size: var(--fs-4);
  font-weight: var(--fw-700);
}





/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service .img {
  margin-inline: auto;
  margin-block-end: 30px;
}

.service .section-title { margin-block-end: 60px; }

.service-card { text-align: center; }

.service-card .card-icon {
  max-width: max-content;
  margin-inline: auto;
}

.service-card .card-title { margin-block: 10px; }

.service-card .card-text { color: var(--spanish-gray); }

.service-card-center { 
  display: flex;
  justify-content: center; 
}



/*-----------------------------------*\
  #CTA
\*-----------------------------------*/

.cta { background-position: 75%; }

.cta-banner { display: none; }

.cta-content { padding-block: 80px var(--section-padding); }

.cta .section-title {
  text-align: left;
  margin-block: 10px 20px;
}

.cta .section-text { margin-block-end: 30px; }

.cta .btn:is(:hover, :focus) {
  background-color: var(--white);
  color: var(--black);
}





/*-----------------------------------*\
  #BRAND
\*-----------------------------------*/

.brand { --section-padding: 100px; }

.brand .has-scrollbar { gap: 0; }

.brand .scrollbar-item { min-width: 50%; }

.brand .scrollbar-item:not(:last-child) { border-inline-end: 1px solid var(--platinum); }

.brand-card {
  max-width: max-content;
  margin-inline: auto;
}





/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
  /* background-color: var(--raisin-black); */
  /* color: var(--battleship-gray); */
  background-color: var(--portland-orange);
  color: var(--white);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.footer-top {
  padding-block-start: 100px;
  border-block-end: 1px solid var(--onyx);
}

.footer-top .container {
  display: grid;
  gap: 30px;
}

.footer .logo {
  color: var(--white);
  margin-block-end: 10px;
}

.footer-text {
  font-size: var(--fs-6);
  margin-block-end: 25px;
}

.footer-text .link {
  display: inline-block;
  color: var(--white);
}

.contact-item-title {
  color: var(--white);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
  padding-block: 10px;
}


.contact-item {
  margin-block-end: 15px;
  color: var(--white);
  font-weight: var(--fw-700);
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-item ion-icon {
  flex-shrink: 0;
  font-size: 22px;
  color: var(--white);
}

.social-list-title {
  color: var(--white);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
  padding-block: 10px;
}

.social-list {
  display: flex;
  gap: 10px;
}

.social-link {
  background-color: var(--white);
  color: var(--black);
  padding: 12px;
  border-radius: 50%;
  transition: var(--transition-1);
}

.social-link:is(:hover, :focus) {
  background-color: var(--portland-orange);
  color: var(--white);
}

.footer-list-title {
  color: var(--white);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
  padding-block: 10px;
}

.footer-link {
  padding-block: 4px;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus) { color: var(--black); }

.footer-bottom { padding-block: 20px; }

.copyright { margin-block-end: 10px; }

.copyright-link { display: inline-block; }

.footer-bottom .img {
  width: 100%;
  max-width: max-content;
}




/*-----------------------------------*\
  #BACK TO TOP
\*-----------------------------------*/

.back-top-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: var(--portland-orange);
  color: var(--white);
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: var(--shadow-2);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-1);
  z-index: 4;
}

.back-top-btn.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px);
}





/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 575px screen
 */

@media (min-width: 575px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 4rem;
    --fs-3: 2.6rem;

  }



  /**
   * REUSED STYLE
   */

  .scrollbar-item { min-width: calc(50% - 15px); }

  .grid-list { grid-template-columns: 1fr 1fr; }



  /**
   * HERO
   */

  .hero {
    display: grid;
    padding-inline-start: 20px;
    justify-content: flex-start;
    align-items: center;
  }



  /**
   * OFFER
   */

  .offer .grid-list { grid-template-columns: 1fr; }



  /**
   * PRODUCT
   */

  .product-card .card-banner { position: relative; }

  .product-card .card-action-btn,
  .product-card .card-banner .hover {
    display: block;
    position: absolute;
  }

  .product-card .card-banner .hover {
    top: 0;
    left: 0;
  }

  .product-card:is(:hover, :focus-within) .default,
  .product-card .hover { opacity: 0; }

  .product-card .default,
  .product-card:is(:hover, :focus-within) .hover { opacity: 1; }

  .product-card .card-action-btn {
    top: 15px;
    right: 15px;
    color: var(--eerie-black);
    font-size: 20px;
    background-color: var(--white);
    border: 1px solid var(--platinum);
    padding: 12px;
    border-radius: 50%;
    transition: var(--transition-1);
    opacity: 0;
  }

  .product-card .card-action-btn:is(:hover, :focus) {
    background-color: var(--portland-orange);
    border-color: var(--portland-orange);
    color: var(--white);
  }

  .product-card:is(:hover, :focus-within) .card-action-btn { opacity: 1; }



  /**
   * CTA
   */

  .cta .img { width: 250px; }

  .cta .h2 { --fs-2: 3.2rem; }



  /**
   * BRAND
   */

  .brand .scrollbar-item { min-width: 25%; }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1fr 1fr; }

}





/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 9rem;

  }



  /**
   * REUSED STYLE
   */

  .scrollbar-item { min-width: calc(33.33% - 20px); }

  .grid-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * HERO
   */

  .hero {
    aspect-ratio: 1512 / 784;
    padding-inline-start: 10%;
  }

  .hero-text { font-size: 2.4rem; }



  /**
   * OFFER
   */

  .offer .grid-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * CTA
   */

  .cta-banner { display: block; }

  .cta .container {
    display: grid;
    grid-template-columns: 1fr 0.75fr;
    align-items: flex-end;
  }



  /**
   * FOOTER
   */

  .footer-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}





/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 12rem;
    --fs-3: 3rem;

    /**
     * spacing
     */

    --section-padding: 50px;

  }



  /**
   * REUSED STYLE
   */

  .container { padding-inline: 30px; }

  .scrollbar-item { min-width: calc(32% - 24px); }



  /**
   * HEADER
   */

  .nav-toggle-btn,
  .navbar-action-btn { display: none; }

  .header {
    /* --color: var(--white);
    background: none; */
  }

  .header.active {
    /* --color: var(--black);
    background-color: var(--white); */
  }

  .header .logo,
  .header .action-btn { color: var(--color); }

  .header .action-btn.user { display: block; }

  .logo { font-size: 3.8rem; }

  .navbar,
  .navbar.active {
    all: unset;
    margin-inline-end: auto;
  }

  .navbar-list {
    display: flex;
    gap: 10px;
  }

  .navbar-link {
    color: var(--color);
    --fs-6: 1.7rem;
    font-weight: var(--fw-700);
    transition: var(--transition-1);
  }

  .header.active .navbar-link:is(:hover, :focus) { color: var(--portland-orange); }



  /**
   * HERO
   */

  .hero { margin-block-start: 0; }



  /**
   * CATEGORY
   */

  .category-card .h3 { --fs-3: 2rem; }



  /**
   * SERVICE
   */

  .service .grid-list { grid-template-columns: repeat(4, 1fr); }

  .service-card .h3 { --fs-3: 2.4rem; }



  /**
   * BRAND
   */

  .brand .scrollbar-item { min-width: 20%; }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1.25fr 1.25fr 0.75fr 0.75fr; }

}





/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 15rem;
    --fs-3: 3.2rem;

  }



  /**
   * REUSED STYLE
   */

  .grid-list { grid-template-columns: repeat(4, 1fr); }



  /**
   * CTA
   */

  .cta-content { padding-block: 100px; }



  /**
   * BRAND, FOOTER
   */

  :is(.brand, .footer) .container {
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;
  }

  .footer { padding-block-start: 40px; }

}
